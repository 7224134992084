import React from "react";
import logo from "../assets/logoo.png";
import { useTranslation } from 'react-i18next';



export const Footer = () => {
  const [t, i18n] = useTranslation("global")
  return (
    <>
      <div className="form-group mb-4 mt-4"></div>
  <div className="row " style={{"background-color": "white", "font-family": "CooperHewitt"}}>
    <div className="col-md-12 col-12 col-sm-12"><br></br></div>
    <div className="col-md-2 col-1 col-sm-1"></div>
    <div className="col-md-8 col-10 col-sm-10">
      <div className="row">
        <div className="col-md-3">
          <div className="text-center">
          <img src={logo} className="img-fluid" />
          </div>
          <p style={{color:"#B3B2B3"}}>BY CROWDHEALTH</p>
        </div>
        <div className="col-md-3 ">
          <p>{t("footer.callUs")}
            <br/>
            <a href="tel:+1214-606-6505" style={{"font-size": "16px"}}>{t("footer.phone")}:  +1 214-606-6505</a>
          </p>          
        </div>
        <div className="col-md-3">
          <p>{t("footer.writeUs")}<br/>
            <a href="mailto:lg@thecrowdhealth.com" style={{"font-size": "16px"}}>{t("footer.e-mail")}: lg@thecrowdhealth.com</a>
          </p>          
        </div>
        <div className="col-md-3">
          <div className="btn-group" role="group" aria-label="Basic example">
            <a Key="1" target="_blank" className="btn" href="https://www.facebook.com/crowdhealthtech?from=web">
              <i className="bi-facebook" style={{"font-size": "2rem", "color": "cornflowerblue"}}></i>
            </a>
            <a Key="2" target="_blank" className="btn" href="https://www.linkedin.com/company/crowdhealth-cloud/?from=web">
              <i className="bi-linkedin" style={{"font-size": "2rem", "color": "cornflowerblue"}}></i>
            </a>
            {/*<!--
            <button type="button" className="btn btn-secondary">Right</button>
            -->*/}
          </div>   
        </div>
      </div>
      <div className="row justify-content-center align-items-center">
        <br/>
        <p>© Copyright <span>2021 Crowdhealth</span></p>
      </div>      
    </div>
    <div className="col-md-2 col-1 col-sm-1"></div>
  </div>
      
    </>
  )
}
export default Footer;