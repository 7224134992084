import React from 'react';
import {Footer} from '../components/footer';
import  Menu from '../components/navbar';

const Layout = ({ children }) => (
  <div className='App'>
    <Menu />
    {children}
   <Footer />
  </div>
);

export default Layout;