import React, { useEffect, useState } from "react";
import app from "./firebase/base";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    try {
      app.auth().onAuthStateChanged((user) => {
        setCurrentUser(user)
        setPending(false)
      })
    } catch (error) {
      console.log("error")
    }
  }, []);

  if (pending) {
    return <>Loading...</>
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};