import * as firebase from "firebase/app";
import "firebase/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyDE8vcyXyhtx6Tuue04FdgUwn-_RZ92maY",
  authDomain: "ivory-honor-304401.firebaseapp.com",
  projectId: "ivory-honor-304401",
  storageBucket: "ivory-honor-304401.appspot.com",
  messagingSenderId: "426543762616",
  appId: "1:426543762616:web:2181a6244542157706784d",
  measurementId: "G-V2C8JR7YHB"
});

export default app;
