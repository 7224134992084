import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Home } from './Home';
import { AuthProvider } from './Auth'
import Layout from './containers/Layout';

const App = () => {
  return (
    <AuthProvider>
      <Layout>
        <Router>
          <Route exact path="/" component={Home} />
        </Router>
      </Layout>
    </AuthProvider>
  )
}
export default App;