import React from 'react';
import system from './assets/system.png';
import dashboard from './assets/dashboard.png';
import feature1 from './assets/feature-01.png';
import feature2 from './assets/feature-02.png';
import feature3 from './assets/feature-03.png';
import feature4 from './assets/feature-04.png';
import feature5 from './assets/feature-05.png';
import feature6 from './assets/feature-06.png';
import feature7 from './assets/feature-07.png';
import feature8 from './assets/feature-08.png';
import benefit2 from './assets/benefits-02.png';
import benefit3 from './assets/benefits-03.png';
import benefit4 from './assets/benefits-04.png';
import benefit5 from './assets/benefits-05.png';
import benefit6 from './assets/benefits-06.png';
import logo from './assets/logoSolo.png';
import apple from './assets/apple.png';
import google from './assets/google.png';
import gif from './assets/grains.gif';
import { useTranslation } from 'react-i18next';

export const Home = () => {
  const [t, i18n] = useTranslation('global');

  return (
    <div className='container'>
      <div className='row mx-0 background' style={{ overflow: 'hidden' }}>
        <div className='col-md-6 '>
          <div className='container mt-4 '>
            <h1
              style={{
                color: '#FFFFFF',
                fontWeight: 'bolder',
                fontSize: '3.5rem',
                paddingTop: '1.2rem',
              }}
            >
              <img
                src={logo}
                style={{ height: '4rem', paddingBottom: '1rem' }}
              />{' '}
              RexSeed
            </h1>
            <h2
              style={{
                color: '#FFFFFF',
                fontSize: '1.8rem',
                fontWeight: 'bold',
              }}
            >
              BY CROWDHEALTH
            </h2>
            <p
              style={{
                color: '#FFFFFF',
                fontSize: '1.2rem',
                fontWeight: 'lighter',
              }}
            >
              {t('bannerHeader.paragraph1')}
            </p>
            <p
              style={{
                color: '#FFFFFF',
                fontSize: '1.2rem',
                fontWeight: 'lighter',
                paddingBottom: '1.2rem',
              }}
            >
              {t('bannerHeader.paragraph2')}
            </p>
          </div>
        </div>
        <div className='col-md-6 mt-4' style={{ float: 'right' }}>
          <img className='img-fluid' src={gif} style={{}} />
        </div>
      </div>

      <div
        classname='mt-4'
        style={{ 'background-color': 'white', paddingTop: '2rem' }}
      >
        {/* -------FEATURES--------  */}

        <div className='container'>
          <div
            className='row text-center'
            id='features'
            style={{ paddingBottom: '2rem', marginTop: '2rem' }}
          >
            <div className='col-md-12 '>
              <h2 style={{ color: 'rgb(99,178,226)', fontWeight: 'bolder' }}>
                {t('features.title')}
              </h2>
            </div>
            <div className='col-md-3'>
              <img
                src={feature1}
                className='img-fluid'
                style={{ width: '70%', padding: '2rem' }}
              />
              <p style={{ fontSize: '1.2rem', fontWeight: 'lighter' }}>
                {t('features.automaticOperation')}
              </p>
            </div>
            <div className='col-md-3'>
              <img
                src={feature2}
                className='img-fluid'
                style={{ width: '70%', padding: '2rem' }}
              />
              <p style={{ fontSize: '1.2rem', fontWeight: 'lighter' }}>
                {t('features.portability')}
              </p>
            </div>
            <div className='col-md-3'>
              <img
                src={feature3}
                className='img-fluid'
                style={{ width: '70%', padding: '2rem' }}
              />
              <p style={{ fontSize: '1.2rem', fontWeight: 'lighter' }}>
                {t('features.mixture')}
              </p>
            </div>
            <div className='col-md-3'>
              <img
                src={feature4}
                className='img-fluid'
                style={{ width: '70%', padding: '2rem' }}
              />
              <p style={{ fontSize: '1.2rem', fontWeight: 'lighter' }}>
                {t('features.sprayPrecision')}
              </p>
            </div>
            <div className='col-md-3'>
              <img
                src={feature5}
                className='img-fluid'
                style={{ width: '70%', padding: '2rem' }}
              />
              <p style={{ fontSize: '1.2rem', fontWeight: 'lighter' }}>
                {t('features.onlineData')}
              </p>
            </div>
            <div className='col-md-3'>
              <img
                src={feature6}
                className='img-fluid'
                style={{ width: '70%', padding: '2rem' }}
              />
              <p style={{ fontSize: '1.2rem', fontWeight: 'lighter' }}>
                {t('features.remoteControl')}
              </p>
            </div>
            <div className='col-md-3'>
              <img
                src={feature7}
                className='img-fluid'
                style={{ width: '70%', padding: '2rem' }}
              />
              <p style={{ fontSize: '1.2rem', fontWeight: 'lighter' }}>
                {t('features.techsupport')}
              </p>
            </div>
            <div className='col-md-3'>
              <img
                src={feature8}
                className='img-fluid'
                style={{ width: '70%', padding: '2rem' }}
              />
              <p style={{ fontSize: '1.2rem', fontWeight: 'lighter' }}>
                {t('features.patentedTechnology')}
              </p>
            </div>
          </div>
        </div>

        <div className='row' id='about' style={{ marginTop: '2rem' }}>
          <div
            className='col-md-6 text-center'
            style={{ padding: '2rem 2rem 2rem 4rem' }}
          >
            <img src={system} className='img-fluid' style={{ width: '80%' }} />
          </div>
          <div className='col-md-6' style={{ padding: '2rem 4rem 2rem 4rem ' }}>
            <h2 style={{ color: 'rgb(99,178,226)', fontWeight: 'bolder' }}>
              {t('about.titleSystem')}
            </h2>
            <p style={{ fontSize: '1.2rem', fontWeight: 'lighter' }}>
              {t('about.paragraph1')}
            </p>
            <p style={{ fontSize: '1.2rem', fontWeight: 'lighter' }}>
              {t('about.paragraph2')}
            </p>
            <p style={{ fontSize: '1.2rem', fontWeight: 'lighter' }}>
              {t('about.paragraph3')}
            </p>
            <div>
              <img src={apple} style={{ height: '70px' }} />
              <img src={google} style={{ height: '60px' }} />
            </div>
          </div>
        </div>

        <div className='row'>
          <div
            className='col-md-6'
            style={{ padding: '2rem 4rem 2rem 4rem', textAlign: 'right' }}
          >
            <h2 style={{ color: 'rgb(99,178,226)', fontWeight: 'bolder' }}>
              {t('about.titlePlatform')}
            </h2>
            <p style={{ fontSize: '1.2rem', fontWeight: 'lighter' }}>
              {t('about.paragraph4')}
            </p>
            <p style={{ fontSize: '1.2rem', fontWeight: 'lighter' }}>
              {t('about.paragraph5')}
            </p>
          </div>
          <div
            className='col-md-6 text-center'
            style={{ padding: '2rem 4rem 2rem 2rem' }}
          >
            <img
              src={dashboard}
              className='img-fluid'
              style={{ width: '80%' }}
            />
          </div>
        </div>

        <div className='container'>
          <div
            className='row text-center'
            id='features'
            style={{ paddingBottom: '2rem' }}
          >
            <div className='col-md-12 '>
              <h2 style={{ color: 'rgb(99,178,226)', fontWeight: 'bolder' }}>
                {t('benefits.title')}
              </h2>
            </div>

            <div className='col-md-4'>
              <img
                src={benefit3}
                className='img-fluid'
                style={{ width: '40%', padding: '1rem' }}
              />
              <p style={{ fontSize: '1.2rem', fontWeight: 'lighter' }}>
                {t('benefits.financialReports')}
              </p>
            </div>
            <div className='col-md-4'>
              <img
                src={benefit2}
                className='img-fluid'
                style={{ width: '40%', padding: '1rem' }}
              />
              <p style={{ fontSize: '1.2rem', fontWeight: 'lighter' }}>
                {t('benefits.consumption')}
              </p>
            </div>
            <div className='col-md-4'>
              <img
                src={benefit4}
                className='img-fluid'
                style={{ width: '40%', padding: '1rem' }}
              />
              <p style={{ fontSize: '1.2rem', fontWeight: 'lighter' }}>
                {t('benefits.detailedPlans')}
              </p>
            </div>
            <div className='col-md-2'></div>
            <div className='col-md-4'>
              <img
                src={benefit5}
                className='img-fluid'
                style={{ width: '40%', padding: '1rem' }}
              />
              <p style={{ fontSize: '1.2rem', fontWeight: 'lighter' }}>
                {t('benefits.manageTeam')}
              </p>
            </div>

            <div className='col-md-4'>
              <img
                src={benefit6}
                className='img-fluid'
                style={{ width: '40%', padding: '1rem' }}
              />
              <p style={{ fontSize: '1.2rem', fontWeight: 'lighter' }}>
                {t('benefits.workDone')}
              </p>
            </div>
          </div>
        </div>

        {/*<div className="row" style={{"paddingBottom":"2rem", "marginTop":"3rem"}}>
            <div className="col-md-6" style={{"padding": "2rem 4rem 2rem 4rem "}} >
                <h2 style={{"color":"rgb(99,178,226)", "fontWeight":"bolder"}}  >OBJETIVOS DE DESARROLLO SOSTENIBLE</h2>
                <p style={{"fontSize":"1.2rem", "fontWeight":"lighter"}}>En 2015, los estados miembros de las Naciones Unidas adoptaron los 17 Objetivos de Desarrollo Sostenible (ODS),  que según lo previsto deberían guiar las acciones de la comunidad internacional por los próximos 15 años.</p>
                <p style={{"fontSize":"1.2rem", "fontWeight":"lighter"}}>La Agenda 2030 ofrece una visión para la alimentación y la agricultura como clave para el desarrollo sostenible. Como empresa estamos comprometidos directamente con 6 ODS. </p>
        
              </div>
              <div className="col-md-6 text-center" style={{"padding": "2rem 2rem 2rem 4rem"}}>
                <div className="row">
                  <div className="col-md-4" style={{"marginTop":"1rem"}}>
                    <a href="http://www.fao.org/sustainable-development-goals/goals/goal-1/es/">
                      <img src={ods1} className="img-fluid" style={{"width":"70%"}} href=""/>
                    </a>
                  </div>
                  <div className="col-md-4" style={{"marginTop":"1rem"}}>
                  <a href="http://www.fao.org/sustainable-development-goals/goals/goal-2/es/">
                  <img src={ods2} className="img-fluid" style={{"width":"70%"}}/>
                  </a>
                  </div>
                  <div className="col-md-4" style={{"marginTop":"1rem"}}>
                  <a href="http://www.fao.org/sustainable-development-goals/goals/sdg-6/es/">
                  <img src={ods3} className="img-fluid" style={{"width":"70%"}}/>
                  </a>
                  </div>
                  <div className="col-md-4" style={{"marginTop":"1rem"}}>
                    <a href="http://www.fao.org/sustainable-development-goals/goals/goal-8/es/">
                  <img src={ods4} className="img-fluid" style={{"width":"70%"}}/>
                  </a>
                  </div>
                  <div className="col-md-4" style={{"marginTop":"1rem"}}>
                  <a href="http://www.fao.org/sustainable-development-goals/goals/goal-10/es/">
                  <img src={ods5} className="img-fluid" style={{"width":"70%"}}/>
                  </a>
                  </div>
                  <div className="col-md-4" style={{"marginTop":"1rem"}}>
                    <a href="http://www.fao.org/sustainable-development-goals/goals/goal-13/es/">
                  <img src={ods6} className="img-fluid" style={{"width":"70%"}}/>
                  </a>
                  </div>
                
                </div>
              </div>
              
            </div>*/}
      </div>

      {/* -------CONTACT--------  */}

      <div className='container background'>
        <div className='form-group text-center pt-4'>
          <h2 style={{ color: '#FFFFFF', fontWeight: 'bolder' }}>
            {t('contact.title')}
          </h2>
          <p
            style={{
              textAlign: 'center',
              fontSize: '1.2rem',
              fontWeight: 'lighter',
            }}
          >
            {t('contact.subtitle')}
          </p>
        </div>
        <form id='contactform' action='contact.php' method='POST'>
          <div className='row mt-4 mb-4' id='contact'>
            <div className='col-md-2'></div>
            <div className='col-md-8'>
              <div className='row mt-3 mb-3'>
                <div className='col-md-4 form-group'>
                  <label
                    style={{
                      'font-family': 'CooperHewitt',
                      'font-weight': '500',
                    }}
                  >
                    {t('contact.name')}*
                  </label>
                  <input
                    type='text'
                    name='name'
                    className='form-control'
                    required
                    minlength='2'
                  />
                </div>
                <div className='col-md-4 form-group'>
                  <label
                    style={{
                      'font-family': 'CooperHewitt',
                      'font-weight': '500',
                    }}
                  >
                    {t('contact.e-mail')}*
                  </label>
                  <input
                    type='email'
                    name='mail'
                    className='form-control'
                    required
                    minlength='4'
                  />
                </div>
                <div className='col-md-4 form-group'>
                  <label
                    style={{
                      'font-family': 'CooperHewitt',
                      'font-weight': '500',
                    }}
                  >
                    {t('contact.phone')}
                  </label>
                  <input type='text' name='phone' className='form-control' />
                </div>
                <div className='col-md-12 form-group'>
                  <label
                    style={{
                      'font-family': 'CooperHewitt',
                      'font-weight': '500',
                    }}
                  >
                    {t('contact.message')}*
                  </label>
                  <textarea
                    className='form-control'
                    rows='5'
                    name='message'
                    required
                    minlength='10'
                  ></textarea>
                </div>
              </div>
              <div className='row '>
                <div className='col-md-12'>
                  {' '}
                  <small>*{t('contact.legendRequired')}</small>
                </div>
              </div>
              <div className='row mt-3 mb-3 justify-content-center align-items-center'>
                <div className='col-md-4 form-group text-center text-muted mx-auto'>
                  <button
                    className='g-recaptcha btn btn-primary'
                    style={{
                      'font-family': 'CooperHewitt',
                      'font-weight': '500',
                      width: '100%',
                      'background-color': '#0c4895',
                      'border-color': '#0c4895',
                    }}
                    data-sitekey='reCAPTCHA_site_key'
                    data-callback='onSubmit'
                    data-action='submit'
                  >
                    {t('contact.buttonSubmit')}
                  </button>
                </div>
              </div>
            </div>
            <div className='col-md-2'></div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Home;
