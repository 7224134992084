import React from 'react';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import logo from '../assets/logoo.png';
import { useTranslation } from 'react-i18next';

const Menu = () => {
  const [t, i18n] = useTranslation('global');
  return (
    <>
      <Navbar
        className='sticky-top'
        collapseOnSelect
        variant='light'
        bg='light'
        expand='lg'
        style={{ 'font-family': 'CooperHewitt' }}
      >
        <Navbar.Brand href='#home'>
          <img src={logo} style={{ height: '30px' }}></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='mr-auto'>
            <Nav.Link href='#features'>{t('navbar.features')}</Nav.Link>
            <Nav.Link href='#about'>{t('navbar.about')}</Nav.Link>

            <Nav.Link href='#contact'>{t('navbar.contact')}</Nav.Link>
            <Nav.Link href='#news'>{t('navbar.news')}</Nav.Link>
          </Nav>
          <Nav.Item className='justify-content-end'>
            <Dropdown>
              <Dropdown.Toggle
                variant='success'
                id='dropdown-basic'
                style={{
                  backgroundColor: '#fff',
                  borderColor: '#fff',
                  color: '#2b2b2b',
                }}
              >
                {t('navbar.lenguage')}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => i18n.changeLanguage('en')}>
                  English
                </Dropdown.Item>
                <Dropdown.Item onClick={() => i18n.changeLanguage('es')}>
                  Español
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Item>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Menu;
